export const PRICE = {
  modifiers: {
    no_mushrooms: 1.0,
    no_onions: 1.0,
    no_peppers: 1.0,
    no_garlic: 1.0,
    no_broccoli: 1.0,
    no_celery: 1.0,
    no_egg: 1.0,
    no_vegetables: 1.0,
    no_chicken: 1.0,
    no_beef: 1.0,
    no_shrimp: 1.0,
    no_meat: 1.0,
    no_sauce: 1.0,
    no_sweet_and_sour_sauce: 0.0,
    no_carrot: 1.0,
    no_gravy: 1.0,
    no_seafood: 1.0,
    no_gluten: 1.0,
    no_soy_sauce: 1.0,
    sauce_on_side: 1.0,
    no: 0.0,
    add_onions: 1.0,
    add_peppers: 1.0,
    add_garlic: 1.0,
    add_broccoli: 1.0,
    add_celery: 1.0,
    add_vegetables: 1.0,
    add_chicken: 1.0,
    add_beef: 1.0,
    add_shrimp: 1.0,
    add_meat: 1.0,
    add_egg: 1.0,
    add_sauce: 1.0,
    add_carrot: 1.0,
    add_gravy: 1.0,
    add_seafood: 1.0,
    add_gluten: 1.0,
    add_soy_sauce: 1.0,
    add_sauce_on_side: 1.0,
    add: 0,
  },
  dine_in_items: {
    takeout_tray: 19.47,
    // buffet
    lunch_buffet: 16.95,
    kid_lunch_buffet: 8.95,
    senior_lunch_buffet: 14.54,
    dinner_buffet: 20.95,
    kid_dinner_buffet: 10.0,
    senior_dinner_buffet: 18.18,
    //cans
    coke_can: 1.75,
    pepsi_can: 1.75,
    gingerale_can: 1.75,
    ice_tea_can: 1.75,
    rootbeer_can: 1.75,
    sprite_can: 1.75,
    diet_coke_can: 1.75,
    orange_crush_can: 1.75,
    seven_up_can: 1.75,
    coke_bottle: 2.75,
    pepsi_bottle: 2.75,
    gingerale_bottle: 2.75,
    diet_coke_bottle: 2.75,
    orange_crush_bottle: 2.75,
    seven_up_bottle: 2.75,
    bottle_natural_water: 1.5,
    glass_water: 0,
    canadian_beer: 5.5,
    blue_beer: 5.5,
    budlight_beer: 5.5,
    coors_light_beer: 5.5,
    budweiser_beer: 5.5,
    heineken_beer: 5.5,
    export_beer: 5.5,
    // liquour
    mix_drink_liquor: 7.0,
    shot_liquor: 6.0,
    rum_and_coke_liquor: 6.5,
    coke_and_whiskey: 6.5,

    // tea
    regular_tea: 2.5,
    jasmine_tea: 2.5,
    coffee: 2.5,
    white_milk: 2.75,
    chocolate_milk: 2.75,
    // juice
    apple_juice: 2.5,
    orange_juice: 2.5,
  },
  combo: {
    special_combo: 20.95,
  },
  menu_items: {
    // ----------------------------------------------------------- appetizers_----------------------------------------------------------- //

    egg_roll: 1.95,
    spring_roll: 1.95,
    chicken_wings: 13.95,
    almond_soo_guy: 13.95,
    deep_fried_wontons: 6.95,
    breaded_shrimp: 13.95,
    breaded_scallops: 16.95,
    chicken_teriyaki: 15.95,
    beef_teriyaki: 15.95,
    bbq_pork_ribs: 18.95,
    bbq_pork_slices: 17.95,
    bobo_tray_for_two: 25.95,
    bobo_tray_for_four: 45.95,
    // ----------------------------------------------------------- soups_----------------------------------------------------------- //

    chicken_noodle_soup: 6.95,
    beef_noodle_soup: 6.95,
    pork_noodle_soup: 6.95,
    hot_and_sour_soup: 5.95,
    wonton_soup_l: 6.95,
    wonton_soup_s: 5.95,
    egg_flower_soup: 5.95,
    fine_noodle_soup: 5.95,
    consomme_soup: 4.0,
    miso_soup: 5.95,

    // ----------------------------------------------------------- sweet and_sour_----------------------------------------------------------- //

    sweet_and_sour_chicken_balls: 13.95,
    pineapple_chicken_balls: 13.95,
    sweet_and_sour_pork: 12.95,
    sweet_and_sour_spare_ribs: 12.95,
    sweet_and_sour_breaded_shrimp: 13.95,
    sweet_and_sour_breaded_scallops: 16.95,
    sweet_and_sour_beef: 13.95,

    // ----------------------------------------------------------- chow_mein_----------------------------------------------------------- //

    chicken_chop_suey: 11.95,
    beef_chop_suey: 11.95,
    pork_chop_suey: 11.95,
    shrimp_chop_suey: 13.95,
    vegetable_chop_suey: 10.95,
    harmony_chop_suey: 13.95,

    chicken_chow_mein: 12.95,
    beef_chow_mein: 12.95,
    pork_chow_mein: 12.95,
    shrimp_chow_mein: 14.95,
    harmony_chow_mein: 14.95,
    vegetable_chow_mein: 11.95,

    // ----------------------------------------------------------- honeygarlic----------------------------------------------------------- //

    honey_garlic_spare_ribs: 14.95,
    honey_garlic_chicken_wings: 14.95,
    honey_garlic_wontons: 7.95,
    honey_garlic_shrimps: 13.95,

    // ----------------------------------------------------------- fried_rices_----------------------------------------------------------- //

    chicken_fried_rice: 11.95,
    beef_fried_rice: 11.95,
    pork_fried_rice: 11.95,
    mushroom_fried_rice: 11.95,
    vegetable_fried_rice: 11.95,
    shrimp_fried_rice: 13.95,
    harmony_special_fried_rice: 13.95,
    steamed_rice_s: 4.5,
    steamed_rice_l: 7.95,
    // ----------------------------------------------------------- vegetable_almond_----------------------------------------------------------- //

    almond_guy_ding: 12.95,
    almond_beef_ding: 12.95,
    almond_bbq_pork_ding: 12.95,
    almond_shrimp_ding: 14.95,
    stir_fried_mixed_vegetables_almond: 12.95,
    // ----------------------------------------------------------- lo_mein_----------------------------------------------------------- //

    plain_lo_mein: 12.95,
    mixed_vegetable_lo_mein: 13.95,
    chicken_lo_mein: 13.95,
    beef_lo_mein: 13.95,
    pork_lo_mein: 13.95,
    shrimp_lo_mein: 14.95,
    harmony_special_lo_mein: 14.95,
    cantonese_chow_mein: 14.95,
    chicken_shanghai: 13.95,
    beef_shanghai: 13.95,
    singapore_chow_mein: 13.95,

    // ----------------------------------------------------------- egg_fu_young ----------------------------------------------------------- //

    chicken_egg_fu_young: 13.95,
    beef_egg_fu_young: 13.95,
    bbq_pork_egg_fu_young: 13.95,
    shrimp_egg_fu_young: 14.95,
    mushroom_egg_fu_young: 13.95,
    // ----------------------------------------------------------- honey_----------------------------------------------------------- //

    curry_chicken_with_vegetables: 13.95,
    curry_beef_with_vegetables: 13.95,
    curry_shrimp_with_vegetables: 14.95,
    curry_chicken_wings_with_vegetables: 14.95,

    // ----------------------------------------------------------- szechaun----------------------------------------------------------- //

    spicy_chicken: 13.95,
    spicy_beef: 13.95,
    kung_po_chicken: 13.95,
    kung_po_beef: 13.95,
    kung_pao_shrimps: 14.95,
    szechuan_chicken: 13.95,
    szechuan_beef: 13.95,
    satay_chicken: 13.95,
    satay_beef: 13.95,
    ginger_beef: 14.95,
    chicken_ma_po_tofu: 13.95,
    beef_ma_po_tofu: 13.95,
    pork_ma_po_tofu: 13.95,

    // ----------------------------------------------------------- sea_food_----------------------------------------------------------- //

    seafood_platter: 16.95,
    seafood_delight: 16.95,
    soo_chow_scallops: 16.95,
    soo_chow_har_kew: 14.95,
    soo_chow_wonton: 13.95,
    scallops_and_mixed_vegetables: 16.95,
    shrimp_lobster_sauce: 14.95,

    // ----------------------------------------------------------- popular_suggestions_----------------------------------------------------------- //

    chicken_broccoli: 13.95,
    beef_broccoli: 13.95,
    beef_mixed_vegetables: 13.95,
    shrimp_mixed_vegetables: 15.95,
    moo_goo_guy_pan: 13.95,
    mixed_oriental_vegetables: 12.95,
    chicken_mushroom: 13.95,
    beef_mushroom: 13.95,
    chicken_snow_peas: 14.95,
    beef_snow_peas: 14.95,
    shrimp_snow_peas: 15.95,
    chicken_with_green_pepper: 13.95,
    beef_with_green_pepper: 13.95,
    shrimp_with_green_pepper: 14.95,
    beef_chinese_green: 13.95,
    pepper_steak: 13.95,
    chicken_tomato: 13.95,
    beef_tomato: 13.95,
    chicken_baby_corn: 14.95,

    // ----------------------------------------------------------- chickens_----------------------------------------------------------- //

    lemon_chicken: 12.95,
    general_tao_chicken: 12.95,
    orange_chicken: 12.95,
    sesame_chicken: 12.95,
    sweet_and_sour_chicken: 12.95,
    // chicken_wings: 12.95,
    // almond_soo_guy: 12.95,
    // ----------------------------------------------------------- chef_suggestions_----------------------------------------------------------- //

    tai_dop_voy: 13.95,
    chicken_mushroom_and_broccoli: 14.95,
    beef_mushroom_and_broccoli: 14.95,
    // soo_chow_wonton: 13.95,
    hong_sew_guy_kew: 13.95,
    beef_and_onion: 14.95,
    wai_guy_kew: 13.95,
    chicken_with_black_bean_sauce: 13.95,
    beef_with_black_bean_sauce: 13.95,
    pepper_spare_ribs: 15.95,
    beef_mushroom_oyster_sauce: 13.95,
    woo_dip_har: 16.95,
    golden_garlic_spare_ribs: 15.95,
    // ----------------------------------------------------------- canadian_dishes_----------------------------------------------------------- //

    spicy_potato_wedges: 7.95,
    fish_and_chips: 10.95,
    french_fries: 5.95,
    onion_rings: 5.95,
    // ----------------------------------------------------------- family_meals_----------------------------------------------------------- //

    dinner_for_2: 32.95,
    dinner_for_3: 49.95,
    dinner_for_4: 59.95,
    dinner_for_5: 69.95,
    dinner_for_6: 89.95,
    dinner_for_8: 109.95,
    sweet_and_sour_sauce_s: 1.0,
    sweet_and_sour_sauce_l: 2.0,
    lemon_sauce_s: 1.0,
    lemon_sauce_l: 2.0,
    gravy_s: 1.5,
    honey_sauce: 1.5,
    chop_sticks: 1.0,
    utensils: 1.0,
    extra_soy_sauce: 1.0,
    extra_plum_sauce: 1.0,
    extra_hot_sauce: 1.0,
    extra_mustard: 1.0,
    dry_noodles: 1.0,
    extra_fortune_cookies: 1.0,
    extra_spicy: 1.0,
    out_of_town_delivery: 6.0,
  },
};
