import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../Assets/Harmony_Logo.png";
import { AiOutlineMenu, AiOutlineClose } from "../icons";
function Navigation(props) {
  const [navOn, setNavOn] = useState(false);
  const [isRed] = useState(props.red);

  const toggleNav = () => {
    setNavOn(!navOn);
  };
  return (
    <>
      <nav
        className="mbNavigation-container"
        style={{
          transform: navOn ? "translateX(0)" : "translateX(-100vw)",
          backgroundColor: isRed ? "#d62828" : "#f7ede2",
        }}
      >
        <button onClick={toggleNav} className="closeNav-container">
          <AiOutlineClose className="close-icon" />
        </button>

        <button onClick={toggleNav} className="openNav-container">
          <AiOutlineMenu className="open-icon" />
        </button>

        <div className="nav-logo-container">
          <NavLink exact to="/" className="nav-link">
            <img src={logo} alt="Harmony Logo" className="nav-logo" />
          </NavLink>
        </div>
        <NavLink
          activeClassName={props.red ? "active-link-white" : "active-link-red"}
          exact
          to="/"
          className="nav-link"
        >
          Home
        </NavLink>
        <NavLink
          activeClassName={props.red ? "active-link-white" : "active-link-red"}
          to="/Buffet"
          className="nav-link"
        >
          Buffet
        </NavLink>
        <NavLink
          activeClassName={props.red ? "active-link-white" : "active-link-red"}
          to="/Info"
          className="nav-link"
        >
          Specials & Info
        </NavLink>
        <NavLink
          activeClassName={props.red ? "active-link-white" : "active-link-red"}
          to="/Menu"
          className="nav-link"
        >
          Menu
        </NavLink>
      </nav>

      <nav className="dtNavigation-container">
        <div className="nav-logo-container">
          <NavLink exact to="/" className="nav-link">
            <img src={logo} alt="Harmony Logo" className="nav-logo" />
          </NavLink>
        </div>
        <NavLink
          activeClassName="active-link"
          exact
          to="/"
          className="nav-link"
        >
          Home
        </NavLink>
        <NavLink
          activeClassName="active-link"
          to="/Buffet"
          className="nav-link"
        >
          Buffet
        </NavLink>
        <NavLink activeClassName="active-link" to="/Info" className="nav-link">
          Specials & Info
        </NavLink>
        <NavLink activeClassName="active-link" to="/Menu" className="nav-link">
          Menu
        </NavLink>
        {/* <div className="update">Buffet is now open on weekends!</div> */}
        {/* <div className="update">
          Please order in advance for Christmas and New Years!
        </div> */}
      </nav>
    </>
  );
}
export default Navigation;
