import React, { useEffect } from "react";
import Navigation from "./Navigation";
import {
  appetizers,
  soups,
  sweetSour,
  chowMein,
  honeyGarlic,
  friedRice,
  vegetableAlmond,
  loMein,
  eggFuYoung,
  curry,
  szechaun,
  seafood,
  popularSuggestions,
  chefSuggestions,
  canadianDishes,
  dinnerTwo,
  dinnerThree,
  dinnerFour,
  dinnerFive,
  dinnerSix,
  dinnerEight,
} from "../Data/Menu_Items";
import Footer from "./Footer";
import { AiOutlinePhone } from "../icons";
import { PRICE } from "../prices";
function Menu() {
  const dishes = [
    appetizers,
    soups,
    sweetSour,
    chowMein,
    honeyGarlic,
    friedRice,
    vegetableAlmond,
    loMein,
    eggFuYoung,
    curry,
    szechaun,
    seafood,
    popularSuggestions,
    chefSuggestions,
    canadianDishes,
  ];
  const familyMeals = [
    dinnerTwo,
    dinnerThree,
    dinnerFour,
    dinnerFive,
    dinnerSix,
    dinnerEight,
  ];

  useEffect(() => {
    const month_in_MM = new Date().getMonth() + 1;

    if (month_in_MM === 8)
      document.getElementById("holiday_banner").style.display = "initial";
  }, []);

  return (
    <>
      <div id="menu" className="menu-container">
        <Navigation />
        <div className="content-container">
          <div className="menu-title">
            <h1>Menu</h1>
          </div>
          <div className="phone-number">
            <p>
              <AiOutlinePhone className="phone-logo" />
            </p>
            <p className="call-title">Call in to place your order:</p>
            <a className="phone1" href="tel:519-842-7007">
              519-842-7007
            </a>
            <a className="phone2" href="tel:519-842-2493">
              519-842-2493
            </a>
          </div>

          <div id="holiday_banner" className="holiday-info">
            <h2>Holiday Update</h2>
            <p>
              {" "}
              Dear customers, please order days in advanced for the following
              holidays:
            </p>
            <ul>
              <li>Christmas Eve</li>
              <li>Christmas Day</li>
              <li>New Years Eve</li>
              <li>New Years Day</li>
            </ul>
            <p>Thank You and Happy Holidays!</p>
            <p>
              (Please be aware wait times may be longer than usual on these
              days)
            </p>
          </div>

          <div className="disclaimer">
            <h2>Please Note:</h2>
            <li>
              Making any customization to orders may result in an additional
              service fee.
            </li>
            <li>
              The <b>Cantonese, Shanghai, and Singapore "Chow Mein"</b> under
              the <b>"Lo Mein"</b> section are <b>noodles</b>. <br />
            </li>
            <li>
              All other <b>Chow Mein</b> dishes are <b>bean sprouts</b>.
            </li>
          </div>

          {/* Special Combos */}
          <div className="combo-container">
            <div className="combo-header">
              <p className="combo-title">
                Special Combos For One: ${PRICE.combo.special_combo}
              </p>
              <p>(Choose Any 5 Items & Order By Number)</p>
            </div>
            <div className="combo-info">
              <p>
                <span>1.</span>
                <span>Spring Roll</span>
              </p>
              <p>
                <span>2.</span>
                <span>Egg Roll</span>
              </p>
              <p>
                <span>3.</span>
                <span>Wonton Soup</span>
              </p>
              <p>
                <span>4.</span>
                <span>Deep Fried Wontons</span>
              </p>
              <p>
                <span>5.</span>
                <span>Beef Friend Rice</span>
              </p>
              <p>
                <span>6.</span>
                <span>Chicken Fried Rice</span>
              </p>
              <p>
                <span>7.</span>
                <span>Chicken Balls</span>
              </p>
              <p>
                <span>8.</span>
                <span>Sweet & Sour Pork</span>
              </p>
              <p>
                <span>9.</span>
                <span>Chicken Wings</span>
              </p>
              <p>
                <span>10.</span>
                <span>Breaded Shrimp</span>
              </p>
              <p>
                <span>11.</span>
                <span>Lemon Chicken</span>
              </p>
              <p>
                <span>12.</span>
                <span>Almond Guy Ding</span>
              </p>
              <p>
                <span>13.</span>
                <span>Almond Beef Ding</span>
              </p>
              <p>
                <span>14.</span>
                <span>Beef Mixed Vegetables</span>
              </p>
              <p>
                <span>15.</span>
                <span>Beef Chop Suey</span>
              </p>
              <p>
                <span>16.</span>
                <span>Chicken Chop Suey</span>
              </p>
              <p>
                <span>17.</span>
                <span>
                  Plain Lo Mein <br />
                  (Extra $1 for Plain Lo Mein)
                </span>
              </p>
            </div>
          </div>

          {/* Menu Items */}
          {[
            "Appetizers",
            "Soups",
            "Sweet & Sour",
            `Chop Suey (Bean-Sprouts)`,
            "Honey Garlic",
            "FriedRice",
            "Vegetable Almond",
            "Lo Mein",
            "Egg Fu Young",
            "Curry",
            "Szechaun",
            "Seafood",
            "Popular Suggestions",
            "Chef Suggestions",
            "Canadian Dishes",
          ].map((section, i) => (
            <div className="item-container" key={i}>
              <p className={`section-title section-title-${i}`}>{section}</p>
              {dishes[i].map((dish, j) => (
                <div key={j} className="item-info">
                  <div className="name-price">
                    <p>{dish.name}</p>
                    <p>${dish.price}</p>
                  </div>
                  <p className="item-description">{dish.description}</p>
                </div>
              ))}
            </div>
          ))}

          <div className="family-title">
            <h1>Take-Out Family Dinners</h1>
          </div>

          {[
            `Dinner for Two: $${PRICE.menu_items.dinner_for_2}`,
            `Dinner for Three: $${PRICE.menu_items.dinner_for_3}`,
            `Dinner for Four: $${PRICE.menu_items.dinner_for_4}`,
            `Dinner for Five: $${PRICE.menu_items.dinner_for_5}`,
            `Dinner for Six: $${PRICE.menu_items.dinner_for_6}`,
            `Dinner for Eight: $${PRICE.menu_items.dinner_for_8}`,
          ].map((section, i) => (
            <div className="item-container" key={i}>
              <p className="family-name">{section}</p>
              {familyMeals[i].map((dish, j) => (
                <p key={j} className="family-info">
                  {dish}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="footer-menu">
        <Footer />
      </div>
    </>
  );
}
export default Menu;
