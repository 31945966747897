import React, { useState } from "react";
import { foodPics } from "../Assets/images";
import Navigation from "./Navigation";
import Footer from "./Footer";
import { AiOutlineCar, GiTwoCoins, AiFillCaretLeft, AiFillCaretRight } from "../icons";
function Takeout() {
  const [imgIndex, setImgIndex] = useState(0);
  const nextImg = () => {
    if (imgIndex < 11) {
      setImgIndex(imgIndex + 1);
    }
  };
  const lastImg = () => {
    if (imgIndex > 0) {
      setImgIndex(imgIndex - 1);
    }
  };
  return (
    <>
      <div className="takeout-container">
        <Navigation red={true} />
        <div className="takeout-title">
          <h1>Takeout Specials & Details</h1>
        </div>

        <div className="takeout-body">
          <div className="takeout-info">
            <div className="discount-container">
              <div className="discount-icon">
                <GiTwoCoins />
              </div>
              <div className="discount-info">
                <p className="discount-title">10% Discount</p>
                <p>(On pick-up orders over $55.00 before tax)</p>
                <p>(Cash Only)</p>
              </div>
            </div>

            <div className="delivery-container">
              <div className="delivery-icon">
                <AiOutlineCar />
              </div>
              <div className="delivery-info">
                <p className="delivery-title">Delivery</p>
                <p>($6 delivery fee limited area)</p>
              </div>
            </div>
          </div>

          <div className="takeout-images">
            <div className="carousel-container">
              <div
                className="images"
                style={{
                  transform: `translateX(${(imgIndex / foodPics.length) * -100}%)`,
                }}
              >
                {foodPics.map((img, i) => (
                  <div key={i} className="image-container">
                    <img alt={img.name} src={img.image} className="image" />
                    <p className="image-name">{img.name}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="carousel-controls">
              <button onClick={lastImg} className="left-arrow">
                <AiFillCaretLeft
                  style={{
                    color: imgIndex === 0 ? "darkgrey" : "white",
                  }}
                />
              </button>
              <div className="carousel-dots">
                {foodPics.map((dot, i) => (
                  <div
                    key={i}
                    style={{
                      backgroundColor: imgIndex === i ? "white" : "darkgrey",
                    }}
                    className="carousel-dot"
                  ></div>
                ))}
              </div>
              <button onClick={nextImg} className="right-arrow">
                <AiFillCaretRight
                  style={{
                    color: imgIndex === 11 ? "darkgrey" : "white",
                  }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="info-footer">
        <Footer />
      </div>
    </>
  );
}
export default Takeout;
