import React from "react";
import logo from "../Assets/Harmony_Logo.png";
import Navigation from "./Navigation";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";

function Home() {
  return (
    <>
      <div className="home-container">
        <Navigation red={true} />
        <div className="animation-container flex items-center justify-center">
          <div className="logo-container">
            <img src={logo} alt="Harmony Logo" className="logo-icon" />
            <h1 className="logo-name">HARMONY RESTAURANT</h1>
            <p className="logo-info">Famous Chinese Food</p>
          </div>
          <div className="menu-btn-container">
            <NavLink
              activeClassName="active-link"
              exact
              to="/menu"
              className="menu-btn"
            >
              MENU
            </NavLink>
          </div>
        </div>
      </div>
      <div className="footer-home">
        <Footer />
      </div>
    </>
  );
}

export default Home;
