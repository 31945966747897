export const chickenBalls = require("./ChickenBalls.jpeg");
export const chopSuey = require("./ChopSuey.jpeg");
export const combo = require("./Combo.jpeg");
export const desert = require("./Desert.jpeg");
export const eggRoll = require("./EggRoll.jpeg");
export const noodles = require("./Noodles.jpeg");
export const potato = require("./Potato.jpeg");
export const salad = require("./Salad.jpeg");
export const shanghai = require("./Shanghai.jpeg");
export const shrimpFriedRice = require("./ShrimpFriedRice.jpeg");
export const springRoll = require("./SpringRoll.jpeg");
export const taiDopVoy = require("./TaiDopVoy.jpeg");
export const wings = require("./Wings.jpeg");
export const wontonSoup = require("./WontonSoup.jpeg");
export const buffet = require("./BuffetAll.jpeg");
export const buffetSectionL = require("./BuffetLeft.jpeg");
export const buffetSectionR = require("./BuffetRight.jpeg");

export const foodPics = [
  {
    id: "card-0",
    image: chickenBalls,
    name: "Chicken Balls",
  },
  {
    id: "card-1",
    image: chopSuey,
    name: "Chop Suey",
  },
  {
    id: "card-2",
    image: combo,
    name: "Special Combo",
  },
  {
    id: "card-3",
    image: eggRoll,
    name: "Egg Rolls",
  },
  {
    id: "card-4",
    image: noodles,
    name: "Plain Lo Mein",
  },
  {
    id: "card-5",
    image: potato,
    name: "Potato Wedges",
  },
  {
    id: "card-6",
    image: shanghai,
    name: "Shanghai Beef",
  },
  {
    id: "card-7",
    image: shrimpFriedRice,
    name: "Shrimp Fried Rice",
  },
  {
    id: "card-8",
    image: springRoll,
    name: "Spring Rolls",
  },
  {
    id: "card-9",
    image: taiDopVoy,
    name: "Tai Dop Voy",
  },
  {
    id: "card-10",
    image: wings,
    name: "Chicken Wings",
  },
  {
    id: "card-11",
    image: wontonSoup,
    name: "Wonton Soup",
  },
];

export const buffetPics = [
  {
    id: "dot-0",
    image: buffet,
    name: "Buffet",
  },
  {
    id: "dot-1",
    image: buffetSectionL,
    name: "Buffet",
  },
  {
    id: "dot-2",
    image: buffetSectionR,
    name: "Buffet",
  },
  {
    id: "dot-3",
    image: desert,
    name: "Desert",
  },
  {
    id: "dot-4",
    image: salad,
    name: "Salad Bar",
  },
];
