import React from "react";
import "./Styles/App.scss";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import Home from "./Components/Home";
import Buffet from "./Components/Buffet";
import Info from "./Components/Info";
import Menu from "./Components/Menu";

function App() {

  return (
    <Router className="app-container">
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/Buffet">
          <Buffet />
        </Route>
        <Route exact path="/Info">
          <Info />
        </Route>
        <Route exact path="/Menu">
          <Menu />
        </Route>
        {/* <Route>
          <Unknown />
        </Route> */}
      </Switch>
    </Router>
  );
}

export default App;
