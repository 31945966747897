import { PRICE } from "../prices";

export const appetizers = [
  {
    name: "Delicious Egg Roll",
    price: PRICE.menu_items.egg_roll,
    description: "",
  },
  {
    name: "Delicious Spring Roll",
    price: PRICE.menu_items.spring_roll,
    description: "",
  },
  {
    name: "B.B.Q Pork Ribs",
    price: PRICE.menu_items.bbq_pork_ribs,
    description: "",
  },
  {
    name: "B.B.Q Pork Slices",
    price: PRICE.menu_items.bbq_pork_slices,
    description: "",
  },
  {
    name: "Chicken Wings (crispy)",
    price: PRICE.menu_items.chicken_wings,
    description: "",
  },
  {
    name: "Almond Soo Guy",
    price: PRICE.menu_items.almond_soo_guy,
    description: "",
  },
  {
    name: "Deep Fried Wontons",
    price: PRICE.menu_items.deep_fried_wontons,
    description: "",
  },
  {
    name: "Chicken Teriyaki",
    price: PRICE.menu_items.chicken_teriyaki,
    description: "",
  },
  {
    name: "Beef Teriyaki",
    price: PRICE.menu_items.beef_teriyaki,
    description: "",
  },
  {
    name: "Breaded Shrimp",
    price: PRICE.menu_items.breaded_shrimp,
    description: "",
  },
  {
    name: "Breaded Scallops",
    price: PRICE.menu_items.breaded_scallops,
    description: "",
  },
  {
    name: "Bo Bo Tray For Two",
    price: PRICE.menu_items.bobo_tray_for_two,
    description: "",
  },
  {
    name: "Bo Bo Tray for Four",
    price: PRICE.menu_items.bobo_tray_for_four,
    description: "",
  },
];

export const soups = [
  {
    name: "Chicken, Beef or Pork Noodle Soup",
    price: PRICE.menu_items.chicken_noodle_soup,
    description: "",
  },
  {
    name: "Hot and Sour Soup",
    price: PRICE.menu_items.hot_and_sour_soup,
    description: "",
  },
  {
    name: "Wonton Soup (L)",
    price: PRICE.menu_items.wonton_soup_l,
    description: "",
  },
  {
    name: "Wonton Soup (S)",
    price: PRICE.menu_items.wonton_soup_s,
    description: "",
  },
  {
    name: "Egg Flower Soup",
    price: PRICE.menu_items.egg_flower_soup,
    description: "",
  },
  {
    name: "Fine Noodle Soup",
    price: PRICE.menu_items.fine_noodle_soup,
    description: "",
  },
  {
    name: "Consomme Soup",
    price: PRICE.menu_items.consomme_soup,
    description: "",
  },
  {
    name: "Miso Soup",
    price: PRICE.menu_items.miso_soup,
    description: "",
  },
];

export const sweetSour = [
  {
    name: "Sweet and Sour Chicken Balls",
    price: PRICE.menu_items.sweet_and_sour_chicken_balls,
    description: "",
  },
  {
    name: "Sweet and Sour Pork",
    price: PRICE.menu_items.sweet_and_sour_pork,
    description: "",
  },
  {
    name: "Sweet and Sour Spare Ribs",
    price: PRICE.menu_items.sweet_and_sour_spare_ribs,
    description: "",
  },
  {
    name: "Sweet and Sour Breaded Shrimp",
    price: PRICE.menu_items.sweet_and_sour_breaded_shrimp,
    description: "",
  },
  {
    name: "Sweet and Sour Breaded Scallops",
    price: PRICE.menu_items.sweet_and_sour_breaded_scallops,
    description: "",
  },
  {
    name: "Sweet and Sour Beef",
    price: PRICE.menu_items.sweet_and_sour_beef,
    description: "",
  },
];

export const chowMein = [
  {
    name: "Chicken and Mushroom Chop Suey",
    price: PRICE.menu_items.chicken_chop_suey,
    description: "",
  },
  {
    name: "Beef and Mushroom Chop Suey",
    price: PRICE.menu_items.beef_chop_suey,
    description: "",
  },
  {
    name: "Pork and Mushroom Chop Suey",
    price: PRICE.menu_items.pork_chop_suey,
    description: "",
  },
  {
    name: "Shrimp and Mushroom Chop Suey",
    price: PRICE.menu_items.shrimp_chop_suey,
    description: "",
  },
  {
    name: "Vegetable and Mushroom Chop Suey",
    price: PRICE.menu_items.vegetable_chop_suey,
    description: "",
  },
  {
    name: "Harmony Chop Suey",
    price: PRICE.menu_items.harmony_chop_suey,
    description: "",
  },
];
export const honeyGarlic = [
  {
    name: "Honey Garlic Chicken Wings",
    price: PRICE.menu_items.honey_garlic_chicken_wings,
    description: "",
  },
  {
    name: "Honey Garlic Spare Ribs",
    price: PRICE.menu_items.honey_garlic_spare_ribs,
    description: "",
  },
  {
    name: "Honey Garlic Shrimps",
    price: PRICE.menu_items.honey_garlic_shrimps,
    description: "",
  },
  {
    name: "Honey Garlic Wontons",
    price: PRICE.menu_items.honey_garlic_wontons,
    description: "",
  },
];
export const friedRice = [
  {
    name: "Chicken Fried Rice",
    price: PRICE.menu_items.chicken_fried_rice,
    description: "",
  },
  {
    name: "Beef Fried Rice",
    price: PRICE.menu_items.beef_fried_rice,
    description: "",
  },
  {
    name: "Pork Fried Rice",
    price: PRICE.menu_items.pork_fried_rice,
    description: "",
  },
  {
    name: "Mushroom Fried Rice",
    price: PRICE.menu_items.mushroom_fried_rice,
    description: "",
  },
  {
    name: "Vegetable Fried Rice",
    price: PRICE.menu_items.vegetable_fried_rice,
    description: "",
  },
  {
    name: "Shrimp Fried Rice",
    price: PRICE.menu_items.shrimp_fried_rice,
    description: "",
  },
  {
    name: "Harmony Special Fried Rice",
    price: PRICE.menu_items.harmony_special_fried_rice,
    description: "",
  },
  {
    name: "Steamed Rice (S)",
    price: PRICE.menu_items.steamed_rice_s,
    description: "",
  },
  {
    name: "Steamed Rice (L)",
    price: PRICE.menu_items.steamed_rice_l,
    description: "",
  },
];

export const vegetableAlmond = [
  {
    name: "Stir Fried Mixed Vegetables Almond",
    price: PRICE.menu_items.stir_fried_mixed_vegetables_almond,
    description: "",
  },
  {
    name: "Almond Guy Ding (Chicken)",
    price: PRICE.menu_items.almond_guy_ding,
    description: "",
  },
  {
    name: "Almond Beef Ding",
    price: PRICE.menu_items.almond_beef_ding,
    description: "",
  },
  {
    name: "Almond B.B.Q Pork Ding",
    price: PRICE.menu_items.almond_bbq_pork_ding,
    description: "",
  },
  {
    name: "Almond Shrimp Ding",
    price: PRICE.menu_items.almond_shrimp_ding,
    description: "",
  },
];

export const loMein = [
  {
    name: "Plain Lo-Mein",
    price: PRICE.menu_items.plain_lo_mein,
    description: "",
  },
  {
    name: "Mixed Vegetable Lo-Mein",
    price: PRICE.menu_items.mixed_vegetable_lo_mein,
    description: "",
  },
  {
    name: "Chicken Lo-Mein (with Mixed Vegetables)",
    price: PRICE.menu_items.chicken_lo_mein,
    description: "",
  },
  {
    name: "Pork Lo-Mein (with Mixed Vegetables)",
    price: PRICE.menu_items.pork_lo_mein,
    description: "",
  },
  {
    name: "Beef Lo-Mein (with Mixed Vegetables)",
    price: PRICE.menu_items.beef_lo_mein,
    description: "",
  },
  {
    name: "Shrimp Lo-Mein (with Mixed Vegetables)",
    price: PRICE.menu_items.shrimp_lo_mein,
    description: "",
  },
  {
    name: "Harmony Special Lo-Mein",
    price: PRICE.menu_items.harmony_special_lo_mein,
    description: "",
  },
  {
    name: "Shanghai Chow Mein (Chicken or Beef)",
    price: PRICE.menu_items.beef_shanghai,
    description: "",
  },
  {
    name: "Singapore Chow Mein",
    price: PRICE.menu_items.singapore_chow_mein,
    description: "",
  },
];

export const eggFuYoung = [
  {
    name: "Chicken Egg Fu Young",
    price: PRICE.menu_items.chicken_egg_fu_young,
    description: "",
  },
  {
    name: "B.B.Q Pork Egg Fu Young",
    price: PRICE.menu_items.bbq_pork_egg_fu_young,
    description: "",
  },
  {
    name: "Beef Egg Fu Young",
    price: PRICE.menu_items.beef_egg_fu_young,
    description: "",
  },
  {
    name: "Mushroom Egg Fu Young",
    price: PRICE.menu_items.mushroom_egg_fu_young,
    description: "",
  },
  {
    name: "Shrimp Egg Fu Young",
    price: PRICE.menu_items.shrimp_egg_fu_young,
    description: "",
  },
];

export const curry = [
  {
    name: "Curry Chicken with Vegetables",
    price: PRICE.menu_items.curry_chicken_with_vegetables,
    description: "",
  },
  {
    name: "Curry Beef with Vegetables",
    price: PRICE.menu_items.curry_beef_with_vegetables,
    description: "",
  },
  {
    name: "Curry Shrimp with Vegetables",
    price: PRICE.menu_items.curry_shrimp_with_vegetables,
    description: "",
  },
  {
    name: "Curry Chicken Wings with Vegetables",
    price: PRICE.menu_items.curry_chicken_wings_with_vegetables,
    description: "",
  },
];

export const szechaun = [
  {
    name: "Spicy Chicken or Beef",
    price: PRICE.menu_items.spicy_chicken,
    description: "",
  },
  {
    name: "Kung Po (Chicken or Beef)",
    price: PRICE.menu_items.kung_po_chicken,
    description: "",
  },
  {
    name: "Kung Pao Shrimps",
    price: PRICE.menu_items.kung_pao_shrimps,
    description: "",
  },
  {
    name: "Ginger Beef",
    price: PRICE.menu_items.ginger_beef,
    description: "",
  },
  {
    name: "Szechuan Chicken Or Beef",
    price: PRICE.menu_items.szechuan_chicken,
    description: "",
  },
  {
    name: "Ma Po Tofu (Beef, Chicken Or Pork)",
    price: PRICE.menu_items.beef_ma_po_tofu,
    description: "",
  },
];

export const seafood = [
  {
    name: "Shrimp Lobster Sauce",
    price: PRICE.menu_items.shrimp_lobster_sauce,
    description: "(Stir fried shrimp with minced porkin special sauce)",
  },
  {
    name: "Seafood Platter",
    price: PRICE.menu_items.seafood_platter,
    description: "(Scallops, shrimp and mixed Chinese vegetables with our own sauce)",
  },
  {
    name: "Soo Chow Scallops",
    price: PRICE.menu_items.soo_chow_scallops,
    description: "Breaded scallops cooked with Chinese Vegetables)",
  },
  {
    name: "Soo Chow Har Kew",
    price: PRICE.menu_items.soo_chow_har_kew,
    description: "(Breaded shrimp and mixed Chinese Vegetables)",
  },
  {
    name: "Seafood Delight",
    price: PRICE.menu_items.seafood_delight,
    description: "",
  },
];

export const popularSuggestions = [
  {
    name: "Moo Goo Guy Pan",
    price: PRICE.menu_items.moo_goo_guy_pan,
    description: "",
  },
  {
    name: "Chicken Snow Peas",
    price: PRICE.menu_items.chicken_snow_peas,
    description: "",
  },
  {
    name: "Chicken Mushroom",
    price: PRICE.menu_items.chicken_mushroom,
    description: "",
  },
  {
    name: "Chicken Tomato",
    price: PRICE.menu_items.chicken_tomato,
    description: "",
  },
  {
    name: "Shrimp with Green Pepper",
    price: PRICE.menu_items.shrimp_with_green_pepper,
    description: "",
  },
  {
    name: "Pepper Steak",
    price: PRICE.menu_items.pepper_steak,
    description: "",
  },
  {
    name: "Beef or Chicken Broccoli",
    price: PRICE.menu_items.beef_broccoli,
    description: "",
  },
  {
    name: "Beef Snow Peas",
    price: PRICE.menu_items.beef_snow_peas,
    description: "",
  },
  {
    name: "Beef Tomato",
    price: PRICE.menu_items.beef_tomato,
    description: "",
  },
  {
    name: "Beef Chinese Green",
    price: PRICE.menu_items.beef_chinese_green,
    description: "",
  },
  {
    name: "Beef Mushroom",
    price: PRICE.menu_items.beef_mushroom,
    description: "",
  },
  {
    name: "Beef Mixed Vegetables",
    price: PRICE.menu_items.beef_mixed_vegetables,
    description: "",
  },
  {
    name: "Shrimp Snow Peas",
    price: PRICE.menu_items.shrimp_snow_peas,
    description: "",
  },
  {
    name: "Chicken Baby Corn",
    price: PRICE.menu_items.chicken_baby_corn,
    description: "",
  },
  {
    name: "Mixed Oriental Vegetables",
    price: PRICE.menu_items.mixed_oriental_vegetables,
    description: "",
  },
];

export const chefSuggestions = [
  {
    name: "Tai Dop Voy",
    price: PRICE.menu_items.tai_dop_voy,
    description: "(Chicken, Shrimp, B.B.Q pork and Beef with Chinese vegetables.)",
  },
  {
    name: "Satay Beef or Chicken",
    price: PRICE.menu_items.satay_beef,
    description: "",
  },
  {
    name: "Orange Chicken",
    price: PRICE.menu_items.orange_chicken,
    description: "",
  },
  {
    name: "Chicken or Beef with Black Bean Sauce",
    price: PRICE.menu_items.chicken_with_black_bean_sauce,
    description: "(Cubed with black bean sauce and garlic)",
  },
  {
    name: "Pepper Spare Ribs",
    price: PRICE.menu_items.pepper_spare_ribs,
    description: "",
  },
  {
    name: "Beef Mushroom with Oyster Sauce",
    price: PRICE.menu_items.beef_mushroom_oyster_sauce,
    description: "",
  },
  {
    name: "Wai Guy Kew",
    price: PRICE.menu_items.wai_guy_kew,
    description: "(Cubed chicken with Chinese vegetables And mushrooms cooked in a special hot sauce.)",
  },
  {
    name: "Beef and Onion",
    price: PRICE.menu_items.beef_and_onion,
    description: "(Tender beef, onion with our own sauce)",
  },

  {
    name: "Beef or Chicken with Mushrooms and Broccoli",
    price: PRICE.menu_items.beef_mushroom_and_broccoli,
    description: "(Beef tenderloin in cubes combined With Chinese vegetables.)",
  },
  {
    name: "General Tao chicken",
    price: PRICE.menu_items.general_tao_chicken,
    description: "",
  },
  {
    name: "Woo Dip Har",
    price: PRICE.menu_items.woo_dip_har,
    description: "(Butterfly shrimp with special sauce.)",
  },
  {
    name: "Hong Sew Guy Kew",
    price: PRICE.menu_items.hong_sew_guy_kew,
    description: "(Breaded chicken pieces with Chinese Vegetables)",
  },
  {
    name: "Soo Chow Wonton",
    price: PRICE.menu_items.soo_chow_wonton,
    description: "(Crispy wonton, chicken and B.B.Q pork With Chinese greens)",
  },
  {
    name: "Golden Garlic Spare Ribs",
    price: PRICE.menu_items.golden_garlic_spare_ribs,
    description: "",
  },
  {
    name: "Cantonese Style Chow Mein",
    price: PRICE.menu_items.cantonese_chow_mein,
    description:
      "(A combination of sliced chicken, B.B.Q pork Shrimp and beef with mixed vegetables and fine egg noodles)",
  },
  {
    name: "Lemon Chicken",
    price: PRICE.menu_items.lemon_chicken,
    description: "",
  },
  {
    name: "Sesame Chicken",
    price: PRICE.menu_items.sesame_chicken,
    description: "",
  },
  {
    name: "Scallops and Mixed Vegetables",
    price: PRICE.menu_items.scallops_and_mixed_vegetables,
    description: "",
  },
];

export const canadianDishes = [
  {
    name: "Spicy Potato Wedges",
    price: PRICE.menu_items.spicy_potato_wedges,
    description: "",
  },
  {
    name: "Fish and Chips",
    price: PRICE.menu_items.fish_and_chips,
    description: "",
  },
  {
    name: "French Fries",
    price: PRICE.menu_items.french_fries,
    description: "",
  },
  {
    name: "Onion Rings",
    price: PRICE.menu_items.onion_rings,
    description: "",
  },
];

// export const dinnerOne = [
//   "Egg Roll",
//   "Chicken Chop Suey",
//   "Chicken Fried Rice",
//   "Sweet & Sour Chicken Balls",
//   "Chicken Wings",
//   "Fortune Cookie",
// ];

export const dinnerTwo = [
  "2 Egg Rolls",
  "Chicken Chop Suey",
  "Chicken Fried Rice",
  "Sweet & Sour Chicken Balls",
  "Fortune Cookies",
];

export const dinnerThree = [
  "3 Egg Rolls",
  "Beef Chop Suey",
  "Chicken Fried Rice",
  "Sweet & Sour Chicken Balls",
  "Sweet & Sour Spare Ribs",
  "Chicken Wings",
  "Fortune Cookies",
];

export const dinnerFour = [
  "4 Egg Rolls",
  "Fried Wontons",
  "Beef Chop Suey",
  "Moo Goo Guy Pan",
  "Chicken Fried Rice",
  "Sweet & Sour Chicken Balls",
  "Chicken Wings",
  "Fortune Cookies",
];

export const dinnerFive = [
  "5 Egg Rolls",
  "Sweet & Sour Chicken Balls",
  "Beef Chop Suey",
  "Tai Dop Voy",
  "Harmony Fried Rice",
  "Fried Wontons",
  "Chicken Wings",
  "Fortune Cookies",
];

export const dinnerSix = [
  "6 Egg Rolls",
  "Fried Wonton",
  "Sweet & Sour Chicken Balls",
  "Sweet & Sour Shrimp",
  "Harmony Fried Rice",
  "Cantonese Chow Mein",
  "Guy Ding",
  "Beef Chop Suey",
  "Chicken Wings",
  "Fortune Cookies",
];
export const dinnerEight = [
  "8 Egg Rolls",
  "Chicken Balls",
  "Chicken Fried Rice",
  "Lemon Chicken",
  "Beef Broccoli",
  "Beef Chop Suey",
  "Harmony Lo Mein",
  "Sweet & Sour Pork",
  "Chicken Wings",
];
